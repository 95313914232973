import * as React from "react"
import { Container, Wrapper, Left, News, Email,
    Socials, Submit, Copyright, Right, IconImg } from "../styles/footerStyles"
import InstaImg from "../images/insta.png"
import FacebookImg from "../images/facebook.png"
import addToMailchimp from 'gatsby-plugin-mailchimp'

class Footer extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        email: '',
      }
    }

    changeEmailHandler = (event) => {
        this.setState({email: event.target.value})
    }
  
    handleSubmit = e => {
        e.preventDefault();
        addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
        .then(data => {
          // I recommend setting data to React state
          // but you can do whatever you want (including ignoring this `then()` altogether)
          console.log(data)
        })
        .catch(() => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
          // see below for how to handle errors
        })
        this.setState({email: ""})
      }
  
    render() {
        return (
        <Container>
            <Wrapper>
                <Left>
                    <News>Follow the News</News>
                    <form onSubmit={this.handleSubmit}>
                    <Email placeholder="Your Email" type="email" name="email" value={this.state.email} onChange={this.changeEmailHandler}/>
                    <Submit type="submit" value="Submit" />
                    </form>
                    
                </Left>
                <Right>
                    <Socials>Check Our Socials</Socials>
                    <a href="https://www.instagram.com/inland_arts_hub" target="_blank"><IconImg src={InstaImg}/></a>
                    <a href="https://www.facebook.com/InlandArts" target="_blank"><IconImg src={FacebookImg}/></a>
                </Right>
                <Copyright>Copyright 2022 JLTech, llc.</Copyright>
            </Wrapper>
        </Container>
    );
}}

export default Footer;

