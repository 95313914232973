import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  min-width: 30vw;
  align-items: flex-start;
  opacity: 1;
  margin: 0;
  padding-right: 10px;
  z-index: 3;
  flex-flow: column nowrap;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
  background-color: #1C1F22;
  position: fixed;
  transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(100%)'};
  top: 0;
  right: 0;
  height: 100vh;
  padding-top: 5.5rem;
  padding-left: 25px;
  margin-top: 0;
  transition: transform 0.3s ease-in-out;
  a:-webkit-any-link {
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }
  li {
    color: #fff;
    font-size: 19px;
    letter-spacing: 1px;
  }
  @media (max-width: 768px) {
    min-width: 45vw;
  }
`;

const MenuItem = styled.li`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 4px;
  font-weight: bold;
  color: #434343;
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <MenuItem><Link to="/">Home</Link></MenuItem>
      <MenuItem><Link to="/blogs">Blog</Link></MenuItem>
      <MenuItem><Link to="/events">Events</Link></MenuItem>
      <MenuItem><Link to="/youth-contest">Youth Contest</Link></MenuItem>
    </Ul>
  )
}

export default RightNav