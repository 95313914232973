import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const StyledBurger = styled.div`
  width: 1.8rem;
  height: ${({ open }) => open ? "31px" : "33px"};
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  transition: 1s;
  transform: ${({ open }) => open ? " translateX(-700%)" : " translateX(0%)"};
  :hover {
    cursor: pointer;
  }
  div {
    width:  31px;
    height: 0.15rem;
    background-color: ${({ open }) => open ? "#fff" : "#fff"};
    opacity: 1;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    margin-left: ${({ open }) => open ? "2px" : 0};
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg) ' : 'rotate(0)'};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => open ? 0 : 1};

    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
  @media (max-width: 1024px) {
    transform: ${({ open }) => open ? " translateX(-600%)" : " translateX(0%)"};

  }
  @media (max-width: 868px) {
    transform: ${({ open }) => open ? " translateX(-425%)" : " translateX(0%)"};

  }
  @media (max-width: 768px) {
    transform: ${({ open }) => open ? " translateX(-775%)" : " translateX(0%)"};

  }
  @media (max-width: 650px) {
    transform: ${({ open }) => open ? " translateX(-500%)" : " translateX(0%)"};

  }
  @media (max-width: 450px) {
    transform: ${({ open }) => open ? " translateX(-400%)" : " translateX(0%)"};

  }
`;

const Burger = () => {
  const [open, setOpen] = useState(false)
  
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open}/>
    </>
  )
}
export default Burger