import styled from 'styled-components'
import { Link } from "gatsby"

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background-color: #F0E1D0;
`;

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 67%;
    max-width: 1240px;
    margin-top: 50px;
    margin-bottom: 50px;
`;

const Left = styled.div`
    text-align: left;
    width: 60%;
`;

const News = styled.div`
    font-size: 28px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        font-size: 20px;
    }
    @media (max-width: 568px) {
        font-size: 16px;
    }
`;

const Email = styled.input`
    margin-top: 3px;    
    border: solid thin #000;
    background: transparent;
    font-size: 14px;
    padding: 5px;
    height: 42px;
    font-family: 'tk-futura-pt-n5';
    width: 60%;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #202020;
        font-size: 14px;
        opacity: 1;
    }
    @media (max-width: 768px) {
        width: -webkit-fill-available; 
      }
`;

const Submit = styled.input`
    color: #394046;
    padding-left: 15px;
    padding-right: 15px;
    border-width: thin;
    margin: 0;
    border: none;
    background: transparent;
    border: solid thin #000;
    margin-left: 15px;
    height: 42px;
    padding-top: 2px;
    @media (max-width: 768px) {
        margin-top: 10px;
        margin-left: 0;
    }
`;

const Copyright = styled.div`
    font-size: 14px;
    margin-top: 75px;
    width: 100%;
`;

const Right = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    width: 25%;
`;

const Socials = styled.div`
    font-size: 28px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        font-size: 20px;
    }
    @media (max-width: 568px) {
        font-size: 16px;
    }
`;

const IconImg = styled.img`
    margin: 0;
    height: 48px;
    margin-left: 5px;
    margin-right: 5px;

`;

export {
    Container,
    Wrapper,
    Left,
    News,
    Email,
    Socials,
    Submit,
    Copyright,
    Right,
    IconImg,
}