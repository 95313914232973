import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';

const Nav = styled.nav`
width: fit-content;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
width: -webkit-fill-available;
justify-content: flex-end;
  .logo {
    padding: 15px 0;
  }


  
`

const Navbar = () => {
  return (
    <Nav>
      <Burger />
    </Nav>
  )
}

export default Navbar