import * as React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import LogoImg from "../images/ia_logo.png"
import ScrollIntoView from 'react-scroll-into-view'
import Mobile from './Nav/Navbar';
import Modal from "./modal"

const Header = () => (
  <Container>
    <Wrapper>
    <LogoCon>
      <Logo src={LogoImg}/>
    </LogoCon>
    <Mobile/>
    {/*
    <Menu>
      <ScrollIntoView style={{cursor: "pointer", display: "flex"}} selector="#about">
        <Item>About</Item>
      </ScrollIntoView>
      <ScrollIntoView style={{cursor: "pointer", display: "flex"}} selector="#donate">
        <Item>Donate</Item>
      </ScrollIntoView>
      <Modal btn={false} />
    </Menu> */}
    
    </Wrapper>
    
  </Container>
)

export default Header

const Container = styled.div`
  display: flex;
  flex-flow: row no wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 80px;
  background-color: #1C1F22;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row no wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  width: 90%;
  min-height: 80px;
`;

const Menu = styled.div`
  display: flex;
`;

const Item = styled.div`
  display: flex;
  font-size: 18px;
  color: #202020;
  margin-right: 15px;
  font-family: serif;
  :hover {
    cursor: pointer;
  }
`;

const LogoCon = styled.div`
  display: flex;
`;

const Logo = styled.img`
  width: auto;
  height: auto;
  max-height: 45px;
  margin: 0;
  border: none;
`;
