import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { ContactBtn, Top, TopTxt, Exit, InfoContainer, InfoWrapper, Left, Right, Name, Info, NameInput, Email, Submit, Message, Stack, HeaderBtn} from "../styles/modalStyles"
import ExitImg from "../images/close_icon.png"
import Contact from "./contact"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: '#F7EEE4',
  border: '2px solid #000',
  boxShadow: 24,
  padding: "0px",
};

export default function TransitionsModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let option = props.btn

  return (
    <div>
        {option
            ?
            <ContactBtn onClick={handleOpen}>Contact Us</ContactBtn>
            :
            <HeaderBtn onClick={handleOpen}>Contact Us</HeaderBtn>
        }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Top>
                <TopTxt>Let's Get In Touch!</TopTxt>
                <Exit onClick={handleClose} src={ExitImg}/>
            </Top>
            <InfoContainer>
                <InfoWrapper>
                    <Left>
                        <Name>Morgan Caldbeck</Name>
                        <Info space>Director of Media / JLTech LLC</Info>
                        <Info>406.261.0421</Info>
                        <Info>Morgan@JLTech</Info>
                    </Left>
                    <Right>
                        <Name>Dario Re</Name>
                        <Info space>Creative Director</Info>
                        <Info>509.655.2044</Info>
                        <Info>dariore555@gmail.com</Info>
                    </Right>
                </InfoWrapper>
            </InfoContainer>
            {/* <Contact/>
            
            <Container style={{paddingTop: "0px"}}>
                <Wrapper style={{justifyContent: "flex-start"}}>
                    <Info>Email Us</Info>
                    <Stack>
                        <NameInput placeholder="Name" type="test" name="name"/>
                        <Email placeholder="Email" type="email" name="email"/>
                    </Stack>
                    <Message id="subject" name="subject" placeholder="Write something.."/>
                    <Submit type="submit" value="Submit"/>
                </Wrapper>
            </Container>*/}
            
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}