import styled from 'styled-components'


const ContactBtn = styled.a`
    font-size: 24px;
    border: solid thin #000;
    color: #202020;
    text-decoration: none;
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'tk-linotype-didot-n7';
    :hover {
        cursor: pointer;
    }
`;

const Top = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 0;
    margin-top: -35px;
`;

const TopTxt = styled.div`
    font-size: 16px;
    color: #F7EEE4;
    text-alight: left;
`;

const Exit = styled.img`
    margin: 0;
    height: 100%;
    :hover {
        cursor: pointer;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-flow: row no wrap;
    justify-centent: space-evenly;
    color: #202020;
    padding: 60px;
    justify-content: center;
    @media (max-width: 1024px) {
        flex-flow: row wrap;

    }
    @media (max-width: 769px) {
        flex-flow: row wrap;
        padding: 30px 0px 25px 0px;

    }
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 80%;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 50%;
    @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: 25px;
    }
`;

const Right = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 50%;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const Name = styled.div`
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    @media (max-width: 769px) {
        font-size: 28px;

    }
    @media (max-width: 500px) {
        font-size: 25px;

    }
`;

const Info = styled.div`
    font-size: 28px;
    width: 100%;
    padding-top: 7px;
    margin-bottom: ${ props => props.space ? '20px' : '0px' };
    @media (max-width: 769px) {
        font-size: 24px;
        margin-bottom: ${ props => props.space ? '10px' : '0px' };
        padding-top: 4px;
    }
    @media (max-width: 500px) {
        font-size: 20px;
        margin-bottom: ${ props => props.space ? '5px' : '0px' };
        padding-top: 2px;
    }
`;

const Stack = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 25%;
`;

const NameInput = styled.input`
    margin-top: 3px;    
    border: solid thin #000;
    background: transparent;
    font-size: 14px;
    padding: 5px;
    height: 42px;
    font-family: 'tk-futura-pt-n5';
    width: 100%;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #202020;
        font-size: 14px;
        opacity: 1;
    }
    @media (max-width: 768px) {
        width: -webkit-fill-available; 
      }
`;

const Email = styled.input`
    margin-top: 3px;    
    border: solid thin #000;
    background: transparent;
    font-size: 16px;
    padding: 5px;
    height: 42px;
    font-family: 'tk-futura-pt-n5';
    width: 100%;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #202020;
        font-size: 14px;
        opacity: 1;
    }
    @media (max-width: 768px) {
        width: -webkit-fill-available; 
      }
`;

const Message = styled.textarea`
    margin-top: 3px;    
    border: solid thin #000;
    background: transparent;
    font-size: 16px;
    padding: 5px;
    height: 87px;
    margin-left: 10px;
    font-family: 'tk-futura-pt-n5';
    width: 60%;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #202020;
        font-size: 14px;
        opacity: 1;
    }
    @media (max-width: 768px) {
        width: -webkit-fill-available; 
      }
`;

const Submit = styled.input`
    color: #394046;
    padding-left: 15px;
    padding-right: 15px;
    border-width: thin;
    margin: 0;
    border: none;
    background: #202020;
    border: solid thin #202020;
    color: #F7EEE4;
    margin-left: 10px;
    height: 42px;
    margin-top: 3px;
`;

const HeaderBtn = styled.a`
    :hover {
        cursor: pointer;
    }
`;


export {
    ContactBtn,
    Top,
    TopTxt,
    Exit,
    InfoContainer,
    InfoWrapper,
    Left,
    Right,
    Name,
    Info,
    Stack,
    NameInput,
    Email,
    Message,
    Submit,
    HeaderBtn,
}